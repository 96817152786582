import React from 'react'
import { isSubmittedCesStep, isUserStep } from '@typedef/chatSteps'
import ChatMessage from '@components/ChatMessage/ChatMessage'
import { useChatParams, useDisplayedChatSteps } from '@store/selectors'
import { useDebugToolbar } from '@context/DebugToolbar'
import { baseApi } from '@store/api'
import { ChatMessageHelpers } from '@containers/ChatBox/ChatMessageHelpers'
import { SubmittedCesStep } from '@components/InputSteps'
import { getStepIdCount } from '@utils/branchingUrl'

const ChatHistory = () => {
  const displayedChatSteps = useDisplayedChatSteps()
  const stateId = useChatParams()?.stateId
  const { open } = useDebugToolbar()
  const { data: debugState } = baseApi.endpoints.getDebug.useQueryState(stateId ?? '')
  const stateUrl = debugState?.isTest ? debugState?.chatLink ?? '' : null

  return (
    <>
      {displayedChatSteps.map((step, index) => {
        const key = `${step.id}-${index}`

        if (isSubmittedCesStep(step)) {
          return (
            <SubmittedCesStep
              key={key}
              step={step.originalStep}
              submittedValue={step.submittedValue}
            />
          )
        }

        if (!('message' in step) || step.message === null) {
          return null
        }

        const shouldShowAvatar =
          index === 0 || (!isUserStep(step) && isUserStep(displayedChatSteps[index - 1]))
        const stepCount = getStepIdCount(displayedChatSteps.slice(0, index + 1))[step.id]

        return (
          <ChatMessage
            stepId={step.id}
            key={key}
            user={isUserStep(step)}
            message={step.message}
            shouldShowAvatar={shouldShowAvatar}
            shouldAnimate={!isUserStep(step)}
            timestamp={step.timestamp}
            helperComponent={
              open && debugState && stateUrl ? (
                <ChatMessageHelpers
                  stepId={step.id}
                  stateUrl={stateUrl}
                  stepCount={stepCount}
                  isUserStep={isUserStep(step)}
                />
              ) : null
            }
          />
        )
      })}
    </>
  )
}

export default ChatHistory
